import React from "react"
import { getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useMailchimp from "../hooks/useMailchimp"

function ProgramsPage({ data }) {
  const image = getImage(data?.prgramsHeroImg)
  const { add, isLoading, isSuccess } = useMailchimp()

  return (
    <Layout>
      <SEO
        keywords={[
          `free flow challenge`,
          `sincerely eden`,
          `challenge`,
          `program`
        ]}
        title="Sincerely Eden | Flow Freedom FREE challenge signup"
        description="Signup and recieve a glimpse of our Flow Freedom Program. Completely FREE."
      />
      <Hero
        cta={true}
        ctaBtnText={"Join now"}
        quote=""
        titleStart={<>A Taste Of Flow Freedom: </>}
        titleEnd={<>FREEBIE!</>}
        subtitle="Get a glimpse into my program by joining the FREE Flow Freedom Challenge sent straight to your inbox."
        image={image}
        onSubmit={inputText => add(inputText, ["10DayChallenge"])}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
    </Layout>
  )
}

export default ProgramsPage

export const query = graphql`
  query ProgramHeroImageQuery {
    prgramsHeroImg: file(relativePath: { eq: "dried-flower-white.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 550
          quality: 100
          transformOptions: { cropFocus: SOUTHEAST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
